import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const AdminServicesPage = () => (
  <div>
    <SEO 
        title="Dedicated Servers Hosting Packages Administration Services" 
        description="Economize time & cash. Let our support staff manage your dedicated servers hosting package for only /month."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-2">
          <div className="text-center mx-auto">
              <p className="section-title"><span className="block text-indigo-600 xl:inline">Dedicated Servers Hosting</span> Packages Administration Services<span role="img" aria-label="emoji">✨ </span></p>
              <p className="section-after-title">
              Economize time & cash. Let our support staff manage your dedicated servers hosting package for only /month.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title text-indigo-800">
              Administration Services
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/monitoring-and-rebooting.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Monitoring & Rebooting</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Being forced to keep a constant eye on what is happening on your dedicated servers hosting package 
                        is quite a time–consuming responsibility. But we’re here to lift that weight off your back so 
                        that you’ll have more time for what’s truly essential – making your website a lot better and 
                        looking after your business. With our Administration Services option, you can sit back and 
                        loosen up, since our sysadmins will be keeping constant track of what is going on on your 
                        server. And we have constructed a special tracking system, which will inform us of any 
                        disruptions taking place.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/backup-space.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Backup Space</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Maintaining a complete backup of everything on your server may take you a lot of time. You need to find a 
                        3rd–party physical location where to store everything; you need to deploy an automatic backup solution; 
                        you’ll need to constantly check pretty much everything. We’ve got a very simple alternative. Our 
                        Administration Services package gives you 50 gigabytes of disk space on a backup server. 
                        Besides, we will help you set up the backup procedure. And since the servers are part 
                        of the very same network, everything works amazingly fast.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/installation-and-troubleshooting.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Installation & Troubleshooting</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        If there is a script failing to install or some problem troubling your hosting server, you can spend your precious 
                        time attempting to solve everything all by yourself or you can let our competent technical support team members 
                        and our sysadmins perform the job on your behalf. Our Administration Services package includes installation 
                        and problem–solving tasks. What this means is that you will have the full, undivided attention of one of 
                        our administrators who will set up anything you want on your server or solve any nasty issue that 
                        obstructs you and stops you from getting your job done.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default AdminServicesPage
